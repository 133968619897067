<template>
  <div class="animated fadeIn">
    <div class="table-container">
      <div class="list-wrapper">
        <div>
          <div class="j_datagrid__header type01">
            <div class="header__title">
              <h5>
                All
                <small>Menu Phase</small>
              </h5>
            </div>

            <div class="header__right">
              <v-flex>
                <button class="jcon_add" @click="setAdd()"></button>
              </v-flex>
            </div>
          </div>

          <j-data-grid
            v-model="selected"
            item-key="phaseSn"
            hide-actions
            draggable
            :headers="setheaders"
            :items="navPhase"
            @row-move-to="onRowMoved"
            @sorted="onSorted"
          >
            <template v-slot:items="props">
              <tr :active="props.selected" @click="propStatus($event, props)">
                <td style="text-align: center;" class="handle">
                  <button class="jcon_order"></button>
                </td>
                <td style="text-align: center;">{{ props.item.no }}</td>
                <td style="text-align: center;">{{ props.item.phaseCode }}</td>
                <td style="text-align: center;">{{ props.item.cnName }}</td>
                <td>{{ props.item.phaseName }}</td>
                <td v-if="UpdateVisible" @click="dbTranster(props)">Update</td>
              </tr>
            </template>
          </j-data-grid>
        </div>
      </div>

      <j-form-modal
        title="Menu Phase"
        ref="formHandler"
        @create="add()"
        @edit="edit()"
        @delete="del()"
        @cancel="cancel()"
        :formMode="formode"
        :resetable="resetable"
        :opened.sync="modOpen"
        :modalType="'type00'"
      >
        <v-container>
          <v-layout wrap>
            <v-flex xs12 :class="{ disabled: formode === 'MOD' }">
              <v-text-field
                v-model="selectedItem.phaseCode"
                required
                label="Phase Code"
                placeholder="Edit after the Code input"
                :rules="codeRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.phaseName"
                required
                label="Phase Title"
                placeholder="Input/Edit phase Title"
                :rules="nameRules"
              ></v-text-field>
            </v-flex>
            <v-flex xs12>
              <v-text-field
                v-model="selectedItem.cnName"
                required
                label="CN_Name"
                placeholder="Input/Edit Cn_Name"
              ></v-text-field>
            </v-flex>            
            <v-flex xs12 class="wrap__multiselect">
              <v-select
                v-model="permitSelected"
                multiple
                item-text="name"
                label="Item Permission"
                placeholder="Select permissions to apply"
                return-object
                :items="permitOptions"
                :menu-props="{ maxHeight: '400', contentClass:'multi_select' }"
              ></v-select>
            </v-flex>
          </v-layout>
        </v-container>
      </j-form-modal>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

    <j-snackbar
      :snackbar="snackbar"
      :snackbarColor="'success'"
      :closeBtnColor="'buttonColor'"
      :positionX="'top'"
      :positionY="'right'"
      :top="'85px'"
      :timeout="2000"
      :mode="'mode'"
      :text="'Priority changed'"
    ></j-snackbar>
  </div>
</template>

<script>
import __C from '@/primitives/_constant_'
import { SystemService } from "@/services"
import JFormModal from "@/components/floating/JFormModal"
import JSnackbar from "@/components/JSnackbar"

export default {
  name: "sysenv-menu-phase",
  components: {
    JFormModal,
    JSnackbar
  },
  data: () => ({
    UpdateVisible: true,
    systemService: null,
    snackbar: false,
    modOpen: false,
    permitOptions: [],
    permitSelected: [],
    selected: [],
    selectedIndex: -2,
    selectedItem: {},
    selectedPrevItem: [],
    headers: [
      {
        type: "drag",
        text: "",
        align: "right",
        value: "",
        width: 30,
        sortable: true
      },
      {
        type: "conf",
        text: "No",
        align: "center",
        value: "no",
        width: 40,
        sortable: true
      },
      {
        type: "text",
        text: "Code",
        align: "center",
        value: "phaseCode",
        width: 50,
        sortable: true
      },
      {
        type: "text",
        text: "CnName",
        align: "center",
        value: "cnName",
        width: 100,
        sortable: true
      },      
      {
        type: "text",
        text: "Title",
        align: "left",
        value: "phaseName",
        width: 100,
        sortable: true
      },
      {
        type: "text",
        text: "Update",
        align: "left",
        value: "Update",
        sortable: true
      }
    ],
    navPhase: [],
    loading: false,
    select: null,
    formode: __C.FORM.EDIT_MODE_NEW,
    valid: false,
    codeRules: [
      v => !!v || "Required",
      v => (!!v && /^[A-Z0-9]*$/.test(v)) || "Uppercase and number only",
      v => (!!v && v.length == 2) || "2 length characters"
    ],
    nameRules: [
      v => !!v || "Required",
      v => (!!v && v.length >= 4) || "At least 4 characters",
      v => (!!v && v.length <= 30) || "Less then 30 characters"
    ],
    pathRules: [
      v =>
        /^[a-zA-Z0-9@/]*$/.test(v) ||
        "Not allowed space and special letters except @ /"
    ],
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {}
  }),
  watch: {
    permitSelected(val) {
      let menuPermits = []
      val.forEach(m => { menuPermits.push(m.code) })
      this.selectedItem.phasePermit = menuPermits.join(",")
    },
  },
  created() {
    this.systemService = new SystemService()
    this.systemService.getNavPhase(res => {
      this.setNavPhase(res)
    })
    this.codeRules.push(v => this.isCodeValid(v) || "Duplicated")
  },
  mounted() {
    this.systemService.getCodeIndex("PERMISSION", res => {
      if (!res) this.permitOptions = []
      else this.permitOptions = res
    })
  },
  computed: {
    menuUpdated: {
      get() {
        return this.$store.state.sysenv.menuUpdated;
      },
      set(value) {
        this.$store.commit("sysenv/setMenuStatusUpdated", value);
      }
    },
    resetable() {
      if (this.selectedIndex < 0) {
        if (!!this.selectedItem.phaseName || !!this.selectedItem.url)
          return true;
        return false;
      } else {
        if (
          this.selectedPrevItem[0].phaseName != this.selectedItem.phaseName ||
          this.selectedPrevItem[0].phasePermit != this.selectedItem.phasePermit ||
          this.selectedPrevItem[0].url != this.selectedItem.url ||
          this.selectedPrevItem[0].direction != this.selectedItem.direction ||
          this.selectedPrevItem[0].cnName != this.selectedItem.cnName
        ) return true;
        return false;
      }
    },
    setheaders() {
      let target = `${__C.HOST_NAME_RESOURCE}`
      if (target == 'https://tortue.jingraphics.com/resources' || target == 'https://demo.jingraphics.com/resources' ) {
        this.headers.pop()
        let lastInd = this.headers.length - 1
        delete this.headers[lastInd].width
        this.UpdateVisible = false
      }
      return this.headers
    },
  },
  methods: {
    setNavPhase(res) {
      this.modOpen = false

      if (!res) res = []

      // 'no' field is generated by v-data-table's items="props" action
      // because of that props.item.no is defined into the data-table's
      // item template, so it doesn't need to be add manually... like...
      // res.forEach(item => { item['no'] = 0 })
      this.navPhase = res

      // this action comes from very first time accessed or page reloaded...
      if (this.selectedIndex == -2) {
        this.selectedIndex = -1

        // if this action comes from that add new menu phase,
        // right added item should be selected manually.
      } else if (this.selectedIndex == -1) {
        this.setEdit(this.navPhase.findIndex(x => x.phaseCode == this.selectedItem.phaseCode))
      } else {
        // if this action comes from that delete existing menu phase,
        // select a forward or backward item.
        if (this.navPhase.findIndex(x => x.phaseCode == this.selectedItem.phaseCode) < 0) {
          if (this.navPhase.length > 0) {
            if (this.navPhase.length >= this.selectedIndex + 1) {
              // Nothing to do...
            } else {
              this.selectedIndex = this.navPhase.length - 1
            }
            this.setEdit(this.selectedIndex)
          } else {
            // this.setAdd()
          }
        } else {
          this.setEdit(this.selectedIndex)
        }
      }
    },
    onRowMoved(val) {
      const rowSelected = this.navPhase.splice(val.oldIndex, 1)[0] // Get the selected row and remove it
      this.navPhase.splice(val.newIndex, 0, rowSelected) // Move it to the new index
      this.snackbar = false
      this.systemService.setPriority(
        {
          newIdex: val.newIndex,
          oldIndex: val.oldIndex,
          targetName: "NAV_PHASE"
        },
        () => {
          this.snackbar = true;
          this.menuUpdated = true;
          this.systemService.getNavPhase(res => {
            this.navPhase = []
            // Need some time-delay for draggable process
            setTimeout(() => { this.setNavPhase(res) })
          })
        }
      );
    },
    onSorted(items) {
      items.forEach((item, index) => { item.no = index + 1 })
      // this.navPhase = items.slice()
    },
    dbTranster(props) {
      const _self = this
 
      this.yes = () => {
        this.msgOpen = false
        this.systemService.transferPhase(props.item, res => {
          _self.menuUpdated = true
        })
        this.yes = () => { }
      }
      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Transfer Phase."
      this.msgInfo.titleDescription = ""
      this.msgInfo.message = "Do you want to transfer Selected Menu Phase?"
      this.msgInfo.button = [true, false, true]
      this.msgInfo.buttonText[0] = "Yes"
      this.msgOpen = true
    },    
    add() {
      const _self = this;

      this.yes = () => {
        this.msgOpen = false
        // /\W/ => /[^A-Za-z0-9_]/
        // +    => Matches the preceding item [\W] 1 or more times.
        this.selectedItem.urlSelf = `/${this.selectedItem.phaseName.toLowerCase().replace(/[\W]+/g, '_')}`
        this.selectedItem.url = `/service${this.selectedItem.urlSelf}`
        this.selectedItem.type = 'Phase'
        this.systemService.putNavPhase(this.selectedItem, () => {
          this.systemService.getNavPhase(this.setNavPhase)
          _self.menuUpdated = true;
        })
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save new Phase Code?";
      this.msgInfo.button = [true, false, true];
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    edit() {
      const _self = this

      this.yes = () => {
        this.msgOpen = false
        this.selectedItem.urlSelf = `/${this.selectedItem.phaseName.toLowerCase().replace(/[\W]+/g, '_')}`
        this.selectedItem.url = `/service${this.selectedItem.urlSelf}`
        this.selectedItem.type = 'Phase'
        this.systemService.updNavPhase(this.selectedItem, res => {
          this.systemService.getNavPhase(this.setNavPhase);
          _self.menuUpdated = true;
        });
        this.yes = () => { };
      };
      this.msgInfo.type = "INFO";
      this.msgInfo.title = "Save Changes.";
      this.msgInfo.titleDescription = "";
      this.msgInfo.message = "Do you want to save this changes?";
      this.msgInfo.button = [true, false, true];
      this.msgInfo.buttonText[0] = "Save";
      this.msgOpen = true;
    },
    del() {
      const _self = this;

      this.yes = () => {
        this.msgOpen = false;
        this.systemService.delNavPhase(this.selectedItem.phaseCode, res => {
          this.systemService.getNavPhase(this.setNavPhase);
          _self.menuUpdated = true;
        });
        this.yes = () => { };
      };

      // 삭제하기 전에 삭제하려는 Phase 데이터와 연결된 Group 데이터가 있으면 삭제를 못 하도록 설정
      this.systemService.getNavRelated(
        "Group",
        this.selectedItem.phaseCode,
        res => {
          if (res == 0) {
            this.msgInfo.type = "WARN";
            this.msgInfo.title = "Action Approval";
            this.msgInfo.titleDescription = "Important Notification";
            this.msgInfo.message = `
            <span style="
              font-style: italic; 
              font-weight: 300; 
              font-size: 12px; 
              color: #f75c02
            ">
              Attention: All of its submenu & items will be deleted.
            </span><br /><br />
            Do you want to delete selected Menu Phase?
          `;
            this.msgInfo.button = [true, false, true];
            this.msgInfo.buttonText = ["Yes", "No", "Cancel"];
            this.msgInfo.buttonText[0] = "Delete";
            this.msgOpen = true;
          } else {
            this.msgInfo.type = "WARN";
            this.msgInfo.title = "Can Not Delete";
            this.msgInfo.titleDescription = "Important Notification";
            this.msgInfo.message =
              "Related Menu Group have " +
              res +
              " of data. " +
              "Please delete relevant data first.";
            this.msgInfo.button = [false, false, true];
            this.msgOpen = true;
          }
        }
      );
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    propStatus(e, props) {
      if (e.target.textContent == 'Update') this.modOpen = false
      else {
      this.selectedIndex = this.navPhase.indexOf(props.item);
      this.selectedItem = Object.assign({}, props.item);

      this.permitSelected = []
      this.selectedItem.phasePermit.split(",").forEach(code => {
        let permit_ = this.permitOptions.find(o => o.code == code)
        if(permit_) this.permitSelected.push(permit_)
      })

      if (this.selectedPrevItem.length > 0) {
        if (this.selectedPrevItem[0].phaseCode === props.item.phaseCode) {
          this.modOpen = true;
          return;
        } else {
          let _selected = props.item;
          this.selected = [_selected];
        }
      } else {
        props.selected = !props.selected;
      }
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
      this.modOpen = true;
      }

    },
    setAdd() {
      this.selectedIndex = -1;
      this.selectedItem = {};
      this.selected = [];
      this.selectedPrevItem = [];
      this.formode = __C.FORM.EDIT_MODE_NEW
      this.$refs.formHandler.formReset();
      this.modOpen = true;
    },
    setEdit(selectedIndex) {
      // myArray.map(x => x.hello).indexOf('stevie')
      this.selectedIndex = selectedIndex;
      this.selectedItem = Object.assign({}, this.navPhase[this.selectedIndex]);
      this.selected = [Object.assign({}, this.navPhase[this.selectedIndex])];
      this.selectedPrevItem = [...this.selected];
      this.formode = __C.FORM.EDIT_MODE_MOD
    },
    reset() {
      if (this.selectedIndex < 0) {
        this.selectedItem = {};
        this.$refs.formHandler.formReset();
      } else {
        this.selectedItem = Object.assign({}, this.selectedPrevItem[0]);
      }
    },
    cancel() {
      this.modOpen = false;
    },
    isCodeValid(phaseCode) {
      if (this.formode == __C.FORM.EDIT_MODE_NEW) {
        return !this.navPhase.find(item => item.phaseCode == phaseCode);
      } else {
        return true;
      }
    }
  }
};
</script>
